import React, {useReducer, createContext} from 'react'
import { Route, Routes, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ErrorPage from "./components/Errorpage";
import Logout from "./components/Logout";

import { initialState, reducer } from "./reducer/UseReducer.js";
import Detials from './components/Details';
import { EditAbout } from './components/EditAbout';
import History from './components/History';
import Errorpage from './components/Errorpage';


// we create a contextAPI 
export const UserContext = createContext();

  

const Routing = () => {
  
  return (
    <>
       <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/details" element={<Detials />} />
     <Route path="/history" element={<History />} />
     <Route path="/about" element={<About />} />
     <Route path="/edit" element={<EditAbout />} />
     <Route path="/contact" element={<Contact />} />
     <Route path="/login" element={<Login />} />
     <Route path="/signup" element={<Signup />} />
     <Route path="/logout" element={<Logout />} />
     <Route path="*" element={<Errorpage />} />
  
  
    </Routes>
    </>
  )
}

const App = () => {

  //* we use useReducer
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
   
      <UserContext.Provider value={{state, dispatch}}>
        
        <Navbar />
        <Routing />

      </UserContext.Provider>
  )
}

export default App

