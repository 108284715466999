import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Navigate } from "react-router-dom";

const Details = () => {
  const [userName, setUserName] = useState();

  // const userHomePage = async () => {
  //   const tokenStr = localStorage.getItem("token");
  //   try {
  //     const res = await axios.get("http://upkeep.crmcity.org:8099/history", {
  //       headers: { Authorization: `Bearer ${tokenStr}` },
  //     });

  //     setUserName(res.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  console.log(userName);

  useEffect(() => {
    // userHomePage();
   const data =  JSON.parse(localStorage.getItem("details"));
   setUserName(data);
  }, []);
  const token = localStorage.getItem("token");

 

if(!token){
  return <Navigate to="/login" />;

}else{ 

  return (
    <>
      <div className="container mt-5">
        <div className="">
          <div className="signup-brand" to="#">
            <p>
              All <span style={{ color: "#019fed" }}>Details</span>
            </p>
            <img
              src={
                "https://icon-library.com/images/flights-icon/flights-icon-15.jpg"
              }
              alt="logo"
            />
          </div>

          {userName &&
            userName?.data?.map((user, index) => (
              <div className="card mt-4">
                <div className="card-header" key={index}>
                 { user.prog_name} 
                </div>
                <div className="card-body">
                  {/* <h5 className="card-title">{userName.origin}</h5> */}
                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <p className="card-text">{userName.destination}</p>
                      <img className="details-brand"
                        src={
                          "https://icon-library.com/images/flights-icon/flights-icon-15.jpg"
                        }
                        alt="logo"
                      />
                    <p className="card-text">{userName.origin}</p>
                    <p className="card-text" style={{width: "30%"}}>{user.rates.map(rate => <span>{rate.label} {rate.rate} <br/></span>)}</p>
                    <a href={user.url} className="btn btn-primary">
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <!-- Background image --> */}
    </>
  );
};
}

export default Details;
