import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

const Home = () => {
   
  const navigate = useNavigate();

  const [user, setUser] = useState({
    source: "",
    destination: "",
    startdate: "",
    enddate: "",
  });

  const { source, destination, startdate, enddate } = user;

  const handleInputs = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const loginUser = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("token");
    const dataToUpdate = {
      source,
      destination,
      startdate,
      enddate,
    };

    try {
      const resp = await fetch("http://upkeep.crmcity.org:8099/home", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(dataToUpdate),
      });
      const data = await resp.json();
      console.log(data.data);
      // window.alert("Update Successfull"); 
      navigate("/details");
      if(data) localStorage.setItem("details",JSON.stringify(data.data));
      
    } catch (error) {
      console.log(error?.message);
    }
    
    //   if (!data) {
    //     window.alert("Invalid Credentials");
    //   } else {
        // window.alert("Update Successfull");
    //     // history.push("/details");
    //   }
    // });
  };

  const cities = [
    { city: "Mumbai", code: "BOM" },
    { city: "Bangalore", code: "BLR" },
    { city: "Chennai", code: "MAA" },
    { city: "Kolkata", code: "CCU" },
    { city: "Lucknow", code: "LKO" },
    { city: "Amritsar", code: "ATQ" },
    { city: "Vishakhapatnam", code: "VTZ" },
    { city: "Kannur", code: "CNN" },
    { city: "Raipur", code: "RPR" },
    { city: "Surat", code: "STV" },
    { city: "Indore", code: "IDR" },
    { city: "Kochi", code: "COK" },
    { city: "Ahmedabad", code: "AMD" },
    { city: "Delhi", code: "DEL" },
    { city: "Goa", code: "GOI" },
    { city: "Pune", code: "PNQ" },
    { city: "Thiruvananthapuram", code: "TRV" },
    { city: "Coimbatore", code: "CJB" },
    { city: "Calicut", code: "CCJ" },
    { city: "Bhubaneshwar", code: "BBI" },
    { city: "Guwahati", code: "GAU" },
    { city: "Varanasi", code: "VNS" },
    { city: "Hyderabad", code: "HYD" },
    { city: "Tiruchirapalli", code: "TRZ" },
    { city: "Nagpur", code: "NAG" },
    { city: "Srinagar", code: "SXR" },
    { city: "Imphal", code: "IMF" },
    { city: "Jaipur", code: "JAI" },
    { city: "Madurai", code: "IXM" },
    { city: "Siliguri", code: "IXB" },
    { city: "Patna", code: "PAT" },
    { city: "Mangalore", code: "IXE" },
    { city: "Chandigarh", code: "IXC" },
    { city: "Andaman & Nicobar", code: "IXZ" },
  ];

  const token = localStorage.getItem("token");

  if(!token){
    return <Navigate to="/login" />;
  
  }else{ 

  return (
    <>
      <div className="home-page">
        <div className="home-div">
          <p className="pt-5">WELCOME</p>
          <h1>
          {
          }
          </h1>
          <h2>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-white">
                <div className="home-brand" to="#">
                <h1 className="mb-4">
                  Award{" "}
                  <span className="text-primary text-gradient">Flight</span>
                </h1>
                <img
                  src={
                    "https://icon-library.com/images/flights-icon/flights-icon-15.jpg"
                  }
                  alt="logo"
                />
              </div>
                <form>
                  {/* <div className="row mb-4">
                    <div className="col">
                      <div className="form">
                        <input
                          type="text"
                          id="form6Example1"
                          className="form-control"
                          value={user.source}
                          name="source"
                          onChange={handleInputs}
                          placeholder="From"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form">
                        <input
                          type="text"
                          id="form6Example1"
                          className="form-control"
                          value={user.destination}
                          name="destination"
                          onChange={handleInputs}
                          placeholder="To"
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="row mb-4">
                    <div className="col">
                      <div className="form">
                        <input
                          id="form6Example1"
                          className="form-control"
                          value={user.destination}
                          name="destination"
                          onChange={handleInputs}
                          list="citycode"
                          placeholder="To"
                        />
                        <datalist id="citycode">
                          {cities.map((option, index) => (
                            <option key={index} value={option.code}>
                              {option.code}
                            </option>
                          ))}
                        </datalist>
                      </div>
                    </div>

                    <div className="col">
                      <div className="form">
                        <input
                          id="form6Example1"
                          className="form-control"
                          value={user.source}
                          name="source"
                          onChange={handleInputs}
                          list="citycode"
                          placeholder="From"
                        />
                        <datalist id="citycode">
                          {cities.map((option, index) => (
                            <option key={index} value={option.code}>
                              {option.code}
                            </option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mb-4">
                    <div className="col">
                      <div className="form" method="POST">
                        <select
                          value={user.source}
                          onChange={handleInputs}
                          name="source"
                          className="form-control"
                        >
                          <option selected>
                            From
                          </option>
                          {cities.map((option, index) => (
                            <option key={index} value={option.code}>
                              {option.code}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col">
                      <div className="form">
                        <select
                          value={user.destination}
                          name="destination"
                          onChange={handleInputs}
                          className="form-control"
                        >
                          <option selected>
                            To
                          </option>
                          {cities.map((option, index) => (
                            <option key={index} value={option.code}>
                              {option.code}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div> */}

                  <div className="row mb-4">
                    <div className="col">
                      <div className="form">
                        <input
                          type="date"
                          id="form6Example1"
                          className="form-control"
                          value={user.startdate}
                          name="startdate"
                          onChange={handleInputs}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form">
                        <input
                          type="date"
                          id="form6Example2"
                          className="form-control"
                          value={user.enddate}
                          name="enddate"
                          onChange={handleInputs}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <!-- Submit button --> */}
                  <Link to="/details">
                    <button
                      type="submit"
                      value="submit"
                      name="submit"
                      onClick={loginUser}
                      className="btn btn-primary btn-block mb-4"
                    >
                      Check Place
                    </button>
                  </Link>
                </form>
              </div>
            </div>
          </h2>
        </div>
      </div>
    </>
  );
};
};

export default Home;
