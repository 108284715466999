import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from 'react-router-dom';

const History = () => {
  const [userName, setUserName] = useState("");

  const userHomePage = async () => {
    const tokenStr = localStorage.getItem("token");
    try {
      const res = await axios.get(
        "http://upkeep.crmcity.org:8099/history",
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      setUserName(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userHomePage();
  }, []);

  const token = localStorage.getItem("token");

  if(!token){
    return <Navigate to="/login" />;
  
  }else{ 

  return (
    <>
      <div className="container mt-5">
        <div className="text-white">
          <div className="signup-brand" to="#">
            <p>
              All <span style={{ color: "#019fed" }}>History</span>
            </p>
            <img
              src={
                "https://icon-library.com/images/flights-icon/flights-icon-15.jpg"
              }
              alt="logo"
            />
          </div>
          <form>
            {/* <!-- 2 column grid layout with text inputs for the first and last names --> */}
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Serial ID</th>
                  <th scope="col">Source</th>
                  <th scope="col">Destination</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {userName &&
                  userName.map((user, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{user.source}</td>
                      <td>{user.destination}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </form>
        </div>
      </div>
      {/* <!-- Background image --> */}
    </>
  );
};
};

export default History;
