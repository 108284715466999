import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, Navigate } from "react-router-dom";

export const EditAbout = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const { first_name, last_name, email } = user;

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadUser();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("token");
    const dataToUpdate = {
      email,
      first_name,
      last_name,
    };

    fetch("http://upkeep.crmcity.org:8099/Account", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataToUpdate),
    })
      .then((response) => {
        const data = response;
        console.log(data);

        if (!data) {
          window.alert("Invalid Credentials");
        } else {
          window.alert("Update Successfull");
          navigate("/about");
        }
      });
  };

  const loadUser = async () => {
    const tokenStr = localStorage.getItem("token");
    const result = await axios.get(`http://upkeep.crmcity.org:8099/Account`, {
      headers: { Authorization: `Bearer ${tokenStr}` },
    });
    setUser(result.data);
  };

  const token = localStorage.getItem("token");

  if(!token){
    return <Navigate to="/login" />;
  
  }else{ 

  return (
    <div className="container" style={{ padding: "100px" }}>
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Edit About</h2>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group mt-1 mb-1">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your First Name"
              name="first_name"
              value={first_name}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="form-group mt-1 mb-1">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your Last Name"
              name="last_name"
              value={last_name}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="form-group mt-1 mb-1">
            <input
              type="email"
              className="form-control form-control-lg"
              placeholder="Enter Your Email"
              name="email"
              value={email}
              onChange={(e) => onInputChange(e)}
            />
          </div>

          <button className="btn btn-primary btn-block">Edit User</button>
          <Link to="/about" className="btn btn-primary btn-block m-1">
            Cancel
          </Link>
        </form>
      </div>
    </div>
  );
};
};
