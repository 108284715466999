import React, { useEffect, useState } from "react";
import aboutpic01 from "../images/aboutpic01.jpg";
import aboutpic from "../images/aboutpic.jpg";

import { Link, Navigate } from "react-router-dom";
import axios from "axios";

const About = () => {
  const [userName, setUserName] = useState("");

  const userHomePage = async () => {
    const tokenStr = localStorage.getItem("token");
    try {
      const res = await axios.get("http://upkeep.crmcity.org:8099/Account", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setUserName(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userHomePage();
  }, []);

  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <div className="container emp-profile">
          <form method="GET">
            <div className="row">
              <div className="col-md-4">
                <div className="profile-img">
                  <img
                    src={
                      userName.first_name === "Arun Choudhary"
                        ? aboutpic01
                        : aboutpic
                    }
                    alt="thapa"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="profile-head">
                  <h5>
                    {userName.first_name} {userName.last_name}
                  </h5>
                  <h6>{userName.email}</h6>
                  <p className="profile-rating mt-3 mb-5">
                    RANKINGS: <span> 1/10 </span>
                  </p>

                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="account-tab"
                        data-toggle="tab"
                        href="#account"
                        role="tab"
                        aria-controls="account"
                        aria-selected="false"
                      >
                        Account
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="preferences-tab"
                        data-toggle="tab"
                        href="#preferences"
                        role="tab"
                        aria-controls="preferences"
                        aria-selected="false"
                      >
                        Preferences
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-2">
                <Link
                  to="/edit"
                  className="profile-edit-btn"
                  name="btnAddMore"
                  value="Edit Profile"
                >
                  Edit About
                </Link>
              </div>
            </div>

            <div className="row">
              {/* left side url  */}
              <div className="col-md-4">
                <div className="profile-work">
                  <p> WORK LINK</p>
                  <a href="!#" target="_thapa">
                    Flight Youtube
                  </a>{" "}
                  <br />
                  <a href="!#" target="_thapa">
                    Flight Instagram
                  </a>{" "}
                  <br />
                  <a href="!#" target="_thapa">
                    Flight Linkedin
                  </a>{" "}
                  <br />
                  <a href="!#" target="_thapa">
                    Flight News
                  </a>
                  <br />
                  <a href="!#" target="_thapa">
                    Flight Skyee
                  </a>{" "}
                  <br />
                  <a href="!#" target="_thapa">
                    Flight Telegram
                  </a>{" "}
                  <br />
                  <a href="!#" target="_thapa">
                    Flight Google
                  </a>{" "}
                  <br />
                </div>
              </div>

              {/* right side data toogle  */}

              <div className="col-md-8 pl-5 about-info">
                <div className="tab-content profile-tab" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div class="mb-3">
                      <p>Dashboard :</p>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <lebal>User Id :</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>250323</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>User Name :</lebal>
                      </div>
                      <div className="col-md-6 ">
                        <p>
                          {userName.first_name} {userName.last_name}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>User Email :</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>{userName.email}</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>User Phone :</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>+91 9988775544</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>User Profession :</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>Flight Manager</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Pills content --> */}
                  <div
                    class="tab-pane fade"
                    id="account"
                    role="tabpanel"
                    aria-labelledby="account-tab"
                  >
                    <form>
                      <div class="mb-3">
                        <p>Account :</p>
                      </div>

                      {/* <!-- Username input --> */}
                      <div class="form-outline mb-4">
                        <input
                          type="text"
                          id="registerUsername"
                          class="form-control"
                          value={`${userName.first_name} ${userName.last_name}`}
                        />
                        <p class="form-label" for="registerUsername">
                          User name
                        </p>
                      </div>

                      {/* <!-- Email input --> */}
                      <div class="form-outline mb-4">
                        <input
                          type="email"
                          id="registerEmail"
                          class="form-control"
                          value={`${userName.email}`}
                        />
                        <p class="form-label" for="registerEmail">
                          User email
                        </p>
                      </div>

                      {/* <!-- Submit button --> */}
                      <button
                        type="submit"
                        class="btn btn-primary btn-block mb-3"
                      >
                        Click Account{" "}
                      </button>
                    </form>
                  </div>
                  {/* <!-- Pills content --> */}

                  {/* <div
                    className="tab-pane fade"
                    id="account"
                    role="tabpanel"
                    aria-labelledby="account-tab"
                  >
                    <h1 className="">Account</h1>

                    <div className="row">
                      <div className="col-md-6">
                        <lebal>Full Name</lebal>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          id="form3Example8"
                          className="form-control form-control-lg"
                          value={`${userName.first_name} ${userName.last_name}`}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>Email</lebal>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          id="form3Example8"
                          className="form-control form-control-lg"
                          value={`${userName.email}`}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* <div
                    className="tab-pane fade"
                    id="preferences"
                    role="tabpanel"
                    aria-labelledby="preferences-tab"
                  >
                    <h1 className="">Perferences</h1>
                    <div className="row">
                      <div className="col-md-6">
                        <lebal>preferences</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>Expert</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>preferences</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>Label</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>preferences</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>Rate</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>preferences</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>URL</p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <lebal>preferences</lebal>
                      </div>
                      <div className="col-md-6">
                        <p>Work</p>
                      </div>
                    </div>
                  </div> */}

                  {/* <!-- Pills content --> */}
                  <div
                    class="tab-pane fade"
                    id="preferences"
                    role="tabpanel"
                    aria-labelledby="preferences-tab"
                  >
                    <form>
                      <div class="mb-3">
                        <p>Airports :</p>
                        <p style={{color: "grey", fontSize: "11px"}}>
                          Search for flights more easily by saving your home
                          airport and other airports you travel through often.
                        </p>
                      </div>

                      {/* <!-- Username input --> */}
                      <div class="form-outline mb-4">
                        <p class="form-label" style={{color: "black"}} for="registerUsername">
                          Home airport
                        </p>
                        <input
                          type="text"
                          id="registerUsername"
                          class="form-control"
                          placeholder="Add a primary home airport"
                        />
                      </div>

                      {/* <!-- Email input --> */}
                      <div class="form-outline mb-4">
                        <p class="form-label" style={{color: "black"}} for="registerEmail">
                          Secondary airports
                        </p>
                        <input
                          type="email"
                          id="registerEmail"
                          class="form-control"
                          placeholder="Search for alternative airports"
                        />
                      </div>

                      {/* <!-- Submit button --> */}
                      {/* <button
                        type="submit"
                        class="btn btn-primary btn-block mb-3"
                      >
                        Click Account{" "}
                      </button> */}
                    </form>
                  </div>
                  {/* <!-- Pills content --> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
};

export default About;
